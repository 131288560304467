import deezer from '../../../assets/music_plat_form/deezer.png'
import amazonMusic from '../../../assets/music_plat_form/amazon_music.png'
import anghami from '../../../assets/music_plat_form/anghami.png'
import napster from '../../../assets/music_plat_form/napster.png'
import itunes from '../../../assets/music_plat_form/itunes.png'
import qobuz from '../../../assets/music_plat_form/qobuz.png'
import shazam from '../../../assets/music_plat_form/shazam.png'
import spotify from '../../../assets/music_plat_form/spotify.png'
import soundcloud from '../../../assets/music_plat_form/soundcloud.png'
import tidal from '../../../assets/music_plat_form/tidal.png'
import tiktok from '../../../assets/music_plat_form/tiktok.png'
import youtube from '../../../assets/music_plat_form/youtube.png'

const Tab2 = () => {
    return (
        <div className='h-screen w-screen grid grid-cols-10 gap-3 content-center'>
            <p className='col-start-2 md:col-start-3 col-span-8 md:col-span-6 text-2xl md:text-4xl font-bold text-center'>
            Hely Deng has a Wide market all over the world
            </p>
            <p className='col-start-2 md:col-start-3 col-span-8 md:col-span-6 text-sm md:text-base font-medium my-4 text-center'>
             We will have access to a wide market Over the world and to our people in the diaspora through Hely Deng Music. Both your content will be stored in the digital environment forever and you will create a cultural awareness all over the world.
            </p>
            <div className='col-start-2 md:col-start-3 col-span-8 md:col-span-6 grid grid-cols-3 md:grid-cols-4 gap-4'>
                <img className='w-32 justify-self-center self-center' src={anghami} alt=""  />
                <img className='w-32 justify-self-center self-center' src={deezer} alt=""  />
                <img className='w-32 justify-self-center self-center' src={qobuz} alt=""  />
                <img className='w-32 justify-self-center self-center' src={spotify} alt=""  />
                <img className='w-32 justify-self-center self-center' src={tidal} alt=""  />
                <img className='w-32 justify-self-center self-center' src={youtube} alt=""  />
                <img className='w-32 justify-self-center self-center' src={napster} alt=""  />
                <img className='w-32 justify-self-center self-center' src={itunes} alt=""  />
                <img className='w-32 justify-self-center self-center' src={amazonMusic} alt=""  />
                <img className='w-32 justify-self-center self-center' src={soundcloud} alt=""  />
                <img className='w-32 justify-self-center self-center' src={tiktok} alt=""  />
                <img className='w-32 justify-self-center self-center' src={shazam} alt=""  />
            </div>
        </div>
    );
}

export default Tab2;