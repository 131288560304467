import supportIlu from '../../../assets/illustration/support_red-svg.svg'
import support from '../../../assets/services_icon/support.png'
import TabContainer from '../../../components/TabContainer';

const Tab3 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={support} label={'Support'}
                description={"For Artists, we will create a video pack for all of your music releases. If he/she has a YouTube channel, we will do upload them in it their channels so that we can connect the channel with our system to earn profit. We, as Hely Deng, will track all kinds of rights and income of your content (adaptation to digital media, design, installation, accurate information about content, etc.)."}
                image={supportIlu}
            />
        </div>
    );
}

export default Tab3;