import verification from '../../../assets/icons/verification.svg'
import copyRight from '../../../assets/icons/copy_right.svg'
import support from '../../../assets/icons/support.svg'
import music from '../../../assets/icons/music.svg'
import monetize from '../../../assets/icons/monetize.svg'
import contentId from '../../../assets/icons/content_id.svg'


const Tab1 = () => {
    return (
        <div className='h-screen w-screen grid grid-cols-10 grid-rows-6 content-center services-background-image'>
            <p className='col-start-2 row-start-2 md:col-start-4 col-span-8 md:col-span-4 self-end  xs:text-md  sm:text-4xl  md:text-2xl  lg:text-4xl text-4xl font-bold text-center text-white mb-4'>
            Hely Deng Music
            </p>
            <p className='col-start-2 row-start-3 md:col-start-4 col-span-8 md:col-span-4 xs:text-xsm sm:text-lg md:text-xl  lg:text-2xl text-4lg  font-medium text-center text-white'>
            Here with Hely Deng We Have Up to 6000+ Videos | 800+ Music Releases | 100+ Artists.
            </p>
            <div className='col-start-2 row-start-5 md:col-start-3 col-span-8 md:col-span-6 grid grid-cols-3 md:grid-cols-6 gap-3'>
                <img className='w-14 justify-self-center self-end' src={support} alt=""  />
                <img className='w-12 justify-self-center self-end' src={music} alt=""  />
                <img className='w-16 justify-self-center self-end' src={copyRight} alt=""  />
                <img className='w-16 justify-self-center self-end' src={monetize} alt=""  />
                <img className='w-16 justify-self-center self-end' src={verification} alt=""  />
                <img className='w-16 justify-self-center self-end' src={contentId} alt=""  />
            </div>
        </div>
    );
}

// <div className='col-start-2 md:col-start-4 col-span-8 md:col-span-4 justify-self-center'>
// <button className='text-sm md:text-base font-light text-center bg-red-700 text-white px-4 py-2 rounded-sm'>
//     More About Us
// </button>
// </div>

export default Tab1;