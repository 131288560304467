import hely_dang_red from '../../../assets/logos/hely_dang_red.svg'
import music from '../../../assets/images/music.jpg'
import TabContainer from '../../../components/TabContainer';


const Tab4 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={hely_dang_red} label={'who we are?'} height={'h-80'}
                description={"Hely Deng Music is conducts digital music and video content distribution, promotion, and marketing of digital platforms, follows the rights of content owners (Music companies, Filmmakers, TV, Artists). \n We strive to be completely business-oriented and transparent. We think that we have added new excitement to the music industry by giving opportunities to TV and record companies with a win-win system. \n We strive to be completely business-oriented and transparent. We think that we have added new excitement to the music industry by giving opportunities to TV and record companies with a win-win system."}
                image={music}
            />
        </div>
    );
}

export default Tab4;