import { NavLink } from "react-router-dom"

const Footer = () => {

    return (
        <div>
            <div className='h-screen w-screen grid grid-cols-10 gap-3 content-center footer-background-image' >
                <div className='col-start-3 col-span-6 mt-8 mb-6 ' >
                    <p className='text-xl text-sm md:text-2xl sm:text-4md xl:text-4xl font-bold text-white'>Hely Deng music</p>
                    <p className='mt-4 text-sm md:text-lg sm:text-2sm xl:text-2lg font-thin text-white my-10'>
                                        Hely Deng is a digital distribution company for the first time in Iraq / Kurdistan Region which was established in early 2019. It conducts digital music and video content distribution, promotion, and marketing of digital platforms in 240 countries all over the world, follows the rights of content owners (Music companies, Filmmakers, TV, Artists) according to global copyrights, protects the copyrights and shares earned profits.
                    Digital Music Service is a digital service provider that delivers your songs or visual content in the easiest and fastest way to all the stores over the world.
                    To provide this job we have started to work with the experienced global stakeholders. 
                    Music and YouTube are among the fastest-growing Internet monetization methods. Therefore, we, as a team, want to offer a special service to the music, film, and TV industry.
                    </p>
                    <div className='flex flex-col sm:flex-row space-x-0 sm:space-x-4'>
                    <NavLink to='/services' ><button className='mt-2 border-2 rounded-sm font-bold py-2 w-64 bg-white text-gray-800'>SERVICES</button></NavLink>
                    <NavLink to='/contact' ><button className='mt-2 border-2 rounded-sm font-bold py-2 w-64 bg-white text-gray-800'>CONTACT US</button></NavLink>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;