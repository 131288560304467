import Carousel from '../../components/carousel/Carousel'
import Dots from '../../components/carousel/IndicatorDots'
import Buttons from '../../components/carousel/Buttons'
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Tab1 from './tabs/Tab1'

const ContactUsPage = () => {
    return (
        <div className='w-screen h-screen'>
            <Header route={'contactUs'}/>
            <Carousel loop={false} auto={false} axis={'y'} widgets={[Dots, Buttons]} className="custom-class">
                <Tab1 className='w-screen h-screen' />
                <Footer />
            </Carousel>
        </div>
    );
}

export default ContactUsPage;