import { NavLink } from "react-router-dom"
import { useState } from "react"
import { ReactComponent as HelyDang1 } from '../assets/logos/hely_dang1.svg'
import { ReactComponent as HelyDang2 } from '../assets/logos/hely_dang2.svg'
import { ReactComponent as Menu } from '../assets/icons/menu.svg'
import { ReactComponent as Close } from '../assets/icons/close.svg'
import { useUpdateArrowColor, useArrowColor, useHeaderColor, useServicesHeaderColor } from '../context/ColorContext'

const Header = (props) => {
    const [menuState, setMenu] = useState(false)
    const headerColor = useHeaderColor()
    const updateArrowColor = useUpdateArrowColor()
    const servicesHeaderColor = useServicesHeaderColor()
    const arrowColor = useArrowColor()

    const closeOpenMenu = () => {
        setMenu(!menuState)
    }

    return (
        <div>
            <div className='absolute top-2 sm:top-8 md:top-10 left-0 right-0 grid grid-cols-10 justify-items-center' style={{ zIndex: 100 }}>
                <div className='col-span-4 col-start-1 sm:col-start-2 justify-self-center md:justify-self-start'>
                    <NavLink to='/' onClick={() => updateArrowColor(false)}>
                        {props.route === 'services' ? <HelyDang2 style={{ height: '2.5rem' }} /> :
                            <HelyDang1 style={{ height: '2.5rem' }} />}
                    </NavLink>
                </div>
                <div className='col-span-3 col-start-8 md:col-start-7 flex space-x-2 justify-self-center md:justify-self-end'>
                    <NavLink onClick={() => updateArrowColor('home')} to='/' activeclassname='active-nav' className={`hidden md:inline nav-link-white`} >
                        HOME
                    </NavLink>
                    <NavLink onClick={() => updateArrowColor('services')} to='/services' activeclassname='active-nav' className={
                        `hidden md:inline ${(arrowColor === 'services' || headerColor) && servicesHeaderColor ? 'nav-link-white' : 'nav-link-dark'}
                       `
                    }>
                        SERVICES
                    </NavLink>
                    {/* <NavLink onClick={() => updateArrowColor('team')} to='/team' activeclassname='active-nav' className={`hidden md:inline nav-link-white`}>
                        TEAM
                    </NavLink> */}
                    <NavLink onClick={() => updateArrowColor('contact')} to='/contact' activeclassname='active-nav' className={`hidden md:inline nav-link-white`}>
                        CONTACT
                    </NavLink>
                    <button className='inline md:hidden' onClick={() => closeOpenMenu()}>
                        <Menu fill={arrowColor === 'services' || headerColor ? '#fff' : '#000'} />
                    </button>
                </div>
            </div>
            {menuState && <div className='h-screen w-screen absolute bottom-0 bg-gray-800' style={{ zIndex: 300 }}>
                <button className='absolute right-10 top-2 sm:top-8' onClick={() => closeOpenMenu()}><Close fill="#f71010" /></button>
                <div className='h-screen w-screen flex flex-col justify-center items-center'>
                    <NavLink onClick={() => updateArrowColor('home')} to='/' activeclassname='active-nav' className='nav-link-menu' >
                        HOME
                    </NavLink>
                    <NavLink onClick={() => updateArrowColor('services')} to='/services' activeclassname='active-nav' className='nav-link-menu'>
                        SERVICES
                    </NavLink>
                    <NavLink onClick={() => updateArrowColor('team')} to='/team' activeclassname='active-nav' className='nav-link-menu'>
                        TEAM
                    </NavLink>
                    <NavLink onClick={() => updateArrowColor('contact')} to='/contact' activeclassname='active-nav' className='nav-link-menu'>
                        CONTACT
                    </NavLink>
                </div>
            </div>}
        </div>
    )
}

export default Header