import monetizeIlu from '../../../assets/illustration/monetize_red-svg.svg'
import monetize from '../../../assets/services_icon/monetize.png'
import TabContainer from '../../../components/TabContainer';


const Tab3 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={monetize} label={'Monetize'}
                description={"If you connect your YouTube channel to Hely Deng Music company, we will earn a serious income through all kinds of programs that are waiting in your archive or that you will produce (clips, concert recordings, animation, news, documentaries, theater, comedy, shows, food programs, cartoons, and children programs, music, etc.) and we will share with you equally. "}
                image={monetizeIlu}
            />
        </div>
    );
}

export default Tab3;