import email from '../../../assets/icons/email.svg'
import phone from '../../../assets/icons/phone.svg'
import location from '../../../assets/icons/location.svg'
import { useState } from 'react'


const ContactUsPage = () => {
    const [stateName, setName] = useState();
    const [statePhoneNumber, setPhoneNumber] = useState();
    const [stateEmail, setEmail] = useState();
    const [stateMessage, setMessage] = useState();

    const handleName = (e) => setName(e.target.value)
    const handlePhoneNumber = (e) => setPhoneNumber(e.target.value)
    const handleEmail = (e) => setEmail(e.target.value)
    const handleMessage = (e) => setMessage(e.target.value)

    // const onSubmit = (e) => {
    //     e.preventDefault();

    //     setMessage('')
    //     setName('')
    //     setEmail('')
    //     setPhoneNumber('')
    // }

    return (
        <div className='h-screen w-screen grid grid-cols-10 gap-3 content-center'>
            <div className='col-start-2 col-span-8 md:col-span-4 md:col-start-2 justify-self-start' >
                <p className='text-xl md:text-4xl font-bold'>HI for contact us</p>
                <div className='flex items-start mt-4 md:mt-16'>
                    <img src={email} alt="" className='w-6 md:w-8 h-auto mr-2 ml-14' />
                    <div>
                        <div className='h-5'></div>
                        <p className='text-xs md:text-sm text-red-600'>Email</p>
                        <p className='texts-sm md:text-base font-bold mt-2'>info@helydang.com</p>
                    </div>
                </div>
                <div className='flex items-start mt-4 md:mt-16 ml-14'>
                    <img src={phone} alt="" className='w-4 md:w-6 h-auto mr-2' />
                    <div>
                        <div className='h-5'></div>
                        <p className='text-xs md:text-sm  text-red-600'>Phone</p>
                        <p className='texts-sm md:text-base font-bold mt-2'>+964 750 386 0646</p>
                    </div>
                </div>
                <div className='flex items-start mt-4 md:mt-16 ml-14'>
                    <img src={location} alt="" className='w-4 md:w-6 h-auto mr-2' />
                    <div>
                        <div className='h-5'></div>
                        <p className='text-xs md:text-sm  text-red-600'>location</p>
                        <p className='texts-sm md:text-base font-bold mt-2'>Iraq, Kurdistan Region, Erbil, MRF Quattro A - 10 - 3</p>
                    </div>
                </div>
            </div>
            <div className='col-start-2 col-span-8 md:col-span-4 md:col-start-6 justify-self-stretch'>
                <form className='flex flex-col' action="https://formsubmit.co/info@helydeng.com" method="POST">
                    <input type="hidden" name="_captcha" value="false"></input>
                    <input type="hidden" name="_subject" value="New submission!"></input>
                    <input type="hidden" name="_next" value="https://helydeng.com/"></input>
                    <input
                        required
                        value={stateName}
                        onChange={handleName}
                        type="text" name="name" placeholder="Your name"
                        className='border-2 border-red-600 px-2 md:px-5 p-2 mt-4 md:mt-10 text-xs md:text-sm rounded-md focus:outline-none font-light text-red-600 placeholder-red-600'
                    />
                    <input
                        required
                        value={stateEmail}
                        onChange={handleEmail}
                        type="email" name="email" placeholder="Your Email"
                        className='border-2 border-red-600 px-2 md:px-5 py-2 mt-4 md:mt-10 text-xs md:text-sm rounded-md focus:outline-none font-light text-red-600 placeholder-red-600'
                    />
                    <input
                        required
                        value={statePhoneNumber}
                        onChange={handlePhoneNumber}
                        type="text" name="phone number" placeholder="Phone number"
                        className='border-2 border-red-600 px-2 md:px-5 py-2 mt-4 md:mt-10 text-xs md:text-sm rounded-md focus:outline-none font-light text-red-600 placeholder-red-600'
                    />
                    <textarea
                        required
                        value={stateMessage}
                        onChange={handleMessage}
                        type="text" name="Message" placeholder="Message"
                        className='border-2 border-red-600 px-2 md:px-5 py-2 mt-4 md:mt-10 text-xs md:text-sm rounded-md focus:outline-none font-light text-red-600 placeholder-red-600'
                    />
                    <button type="submit" className='text-sm md:text-base font-light text-center bg-red-700 text-white px-4 py-2 rounded-md mt-6 self-start'>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ContactUsPage;