import hely_dang_red from '../../../assets/logos/hely_dang_red.svg'
import home from '../../../assets/images/home.png'
import TabContainer from '../../../components/TabContainer';


const Tab3 = () => {
    return (
        <div className='w-screen h-screen '>
            <TabContainer imageLabel={hely_dang_red} label={'Who are our clients?'} height={'h-80'}
                description={"Hely Deng Music is a copyright holder of many prominent artists (Hasan Zirak, Ardalan Bakr, Nasser Razazi, Mamle, Najmadin Gholami, Shahen Talabani, Amir Morad and much more). We also manage lots of YouTube channel TVs, Entertainment, Music and Artist channel (Khak Tv, Aro YouTube, Hawbir Chwarbaxi, Stereo Sayran, Diyar Tube HD, Aziz Waisi, Hamis Osman, Aryos Production, Shahryar Hassan, Paywand Jaff and much more).We are also manage a bunch of record labels (Aro YouTube, Stereo Sayran, Aryos Production)."}
                image={home}
            />
        </div>
    );
}

export default Tab3;