import { NavLink } from "react-router-dom"

const Tab1 = () => {
    return (
        <div className='h-screen w-screen grid grid-cols-10 gap-3 content-center home-background-image'>
            <p className='col-start-2 md:col-start-4 col-span-8 md:col-span-4  font-bold text-center  xs:text-md  sm:text-4xl  md:text-2xl  lg:text-4xl text-4xl'>
            Hely Deng Music
            </p>
            <p className='col-start-2 md:col-start-4 col-span-8 md:col-span-4 font-medium my-4 text-center  xs:text-xsm sm:text-lg md:text-xl  lg:text-2xl text-4lg '>
The biggest digital distribution company for the first time in Iraq / Kurdistan region.
            </p>
            <div className='col-start-2 md:col-start-4 col-span-8 md:col-span-4 justify-self-center'>
            <NavLink to='/services' ><button className='text-sm md:text-base font-light text-center bg-red-700 text-white px-4 py-2 rounded-sm'>More About Us</button></NavLink>
            </div>
        </div>
    );
}

export default Tab1;