import React from 'react'
import propTypes from 'prop-types'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow_down.svg'
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow_up.svg'
import { ReactComponent as Facebook } from '../../assets/icons/facebook.svg'
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg'
import { ReactComponent as Instagram } from '../../assets/icons/instagram.svg'
import { useUpdateHeaderColor, useHeaderColor, useArrowColor, useUpdateServicesHeaderColor } from '../../context/ColorContext'

export default function Buttons(props) {
  // have loop inside props
  const { index, total, prevHandler, nextHandler } = props
  const updateHeaderColor = useUpdateHeaderColor()
  const updateServicesHeaderColor = useUpdateServicesHeaderColor()
  const headerColor = useHeaderColor()
  const arrowColor = useArrowColor()

  return (
    <div className='absolute bottom-5 left-0 right-0 grid grid-cols-10' style={{ zIndex: 100 }}>
      <div className='col-span-2 col-start-2 justify-self-center md:justify-self-start flex space-x-0 sm:space-x-2 '>
        <div onClick={() => {
          if (index === 0) return;
          prevHandler()
          if (headerColor)
            updateHeaderColor(false)
          updateServicesHeaderColor(index !== 0)
        }}>
          <ArrowUp className='h-4' fill={index === total - 1 || (index === 0 && arrowColor !== 'contact') ? '#fff' : '#5033ff'} style={{
            visibility:
              index !== 0 ? 'visible' : 'hidden'
          }} />
        </div>
        <div style={{ width: 30 }}></div>
        <div onClick={() => {
          if (index === total - 1) return;
          nextHandler()
          updateHeaderColor(index + 2 === total)
          updateServicesHeaderColor(index + 2 !== total)
        }}>
          <ArrowDown className='h-4' fill={index === total - 1 || (index === 0 && arrowColor !== 'contact') ? '#fff' : '#5033ff'} style={{
            userSelect: 'none', visibility:
              index !== total - 1 ? 'visible' : 'hidden'
          }} />
        </div>
      </div>
      <div className='col-span-2 col-start-8 justify-self-center md:justify-self-end flex space-x-2'>
        <a href='https/test.com'>
          <Instagram fill={index === total - 1 || (index === 0 && arrowColor !== 'contact') ? '#fff' : '#5033ff'} className='h-4' />
        </a>
        <a href='https/test.com'>
          <Youtube fill={index === total - 1 || (index === 0 && arrowColor !== 'contact') ? '#fff' : '#5033ff'} className='h-4' />
        </a>
        <a href='https/test.com'>
          <Facebook fill={index === total - 1 || (index === 0 && arrowColor !== 'contact') ? '#fff' : '#5033ff'} className='h-4' />
        </a>
      </div>
    </div>
  )
}

Buttons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func
}
