import React from 'react'
import PropTypes from 'prop-types'

function Dot(props) {
  return (
    <span style={{
      display: 'flex',
      alignSelf: 'flex-end',
      height: '16px',
      width: '16px',
      borderRadius: '20px',
      backgroundColor: '#5033ff',
      margin: '7px 20px',
      opacity: props.selected ? '1' : '0.1',
      transitionDuration: '300ms'
    }} />
  )
}

export default function IndicatorDots(props) {
  const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    top: '30vh',
    padding: '0 20px',
    textAlign: 'center',
  }

  if (props.total < 2) {
    // Hide dots when there is only one dot.
    return <div style={wrapperStyle} />
  } else {
    return (
      <div style={wrapperStyle}>{
        Array.apply(null, Array(props.total)).map((x, i) => {
          return <Dot key={i} selected={props.index === i} />
        })
      }</div>
    )
  }
}

IndicatorDots.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

