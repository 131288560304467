import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './pages/home/HomePage'
import ContactPage from './pages/contact_us/ContactUsPage'
import SendEmailPage from './pages/contact_us/SendEmailPage'
import ServicesPage from './pages/services/ServicesPage'
import TeamPage from './pages/team/TeamPage'
import { HeaderColorProvider } from './context/ColorContext'
import music from './assets/music/Brussels.mp3'
import { useEffect } from 'react'
import useSound from 'use-sound';

function App() {
  // const [play, { stop }] = useSound(music);

  // useEffect(() => {
  //   play()
  //   console.log('test')
  // },[])

  return (
    <HeaderColorProvider>
      <BrowserRouter>
        {
          // <TwelveGrid />
        }
        <div>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
            <Route path="/services" element={<ServicesPage />}></Route>
            {/* <Route path="/team" element={<TeamPage />}></Route> */}
            <Route path="/sendEmail" element={<SendEmailPage />}></Route>
          </Routes>
          {
            //<Footer />
          }
        </div>
      </BrowserRouter>
    </HeaderColorProvider>
  );
}

export default App;
