import musicIlu from '../../../assets/illustration/music_red-svg.svg'
import music from '../../../assets/services_icon/music.png'
import TabContainer from '../../../components/TabContainer';

const Tab3 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={music} label={'Music'}
                description={"For TVs, we will manage your YouTube channel and connect it to our system. We will assist you to manage your channel properly. Hely Deng Music will be with you to provide all kinds of content (documentary, film, TV series, program format rights, etc.) with its business partners. In addition, we will cooperate with you in preparing and running advertising campaigns in the local or international arena. We will protect your videos from all kinds of unfair use, abuse of copy."}
                image={musicIlu}
            />
        </div>
    );
}

export default Tab3;