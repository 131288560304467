import Carousel from '../../components/carousel/Carousel'
import Dots from '../../components/carousel/IndicatorDots'
import Buttons from '../../components/carousel/Buttons'
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import Tab1 from './tabs/Tab1';
import Tab2 from './tabs/Tab2';
import Tab3 from './tabs/Tab3';
import Tab4 from './tabs/Tab4';

const HomePage = () => {
    return (
        <div className='w-screen h-screen'>
            <Header route={'home'}/>
            <Carousel loop={false} auto={false} axis={'y'} widgets={[Dots, Buttons]} className="custom-class">
                <Tab1 className='w-screen h-screen' />
                <Tab2 className='w-screen h-screen' />
                <Tab4 className='w-screen h-screen' />
                <Tab3 className='w-screen h-screen' />
                <Footer />
            </Carousel>
        </div>
    );
}

export default HomePage;