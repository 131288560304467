const TabContainer = (props) => {
    return (
        <div className='h-screen w-screen grid grid-cols-10 gap-3 content-center mt-4'>
            <div className='col-start-2 col-span-8 lg:col-span-3 lg:col-start-3 justify-self-start' >
                <div className='flex items-center'>
                    <img src={props.imageLabel} alt="" className='w-12 h-auto mr-4' />
                    <p className='text-2xl md:text-4xl font-bold'>{props.label}</p>
                </div>
                <p className='mt-4 xs:text-xsm sm:text-xsm  lg:text-lg text-sm md:text-md'>
                    {props.description}
                </p>
            </div>
            <div className='col-start-2 col-span-8 lg:col-span-3 lg:col-start-6  justify-self-center self-center sm:mt-4 mt-8 rounded'>
                <img src={props.image} alt="" className={`${props.height || 'h-50'} w-auto mr-4 rounded red  mb-8`}/>
            </div>
        </div>
    );
}

export default TabContainer;