import copyrightIlu from '../../../assets/illustration/copy_right_red-svg.svg'
import copyright from '../../../assets/services_icon/copyright.png'
import TabContainer from '../../../components/TabContainer';

const Tab3 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={copyright} label={'Copyright'}
                description={"We will have registered your products for 50 years following the international copyright laws and will have protected them from all kinds of unfair use, abuse of copy. In addition, you will be authorized to allow the use of the contents of products contrary to their sentimental value will be prevented. "}
                image={copyrightIlu}
            />
        </div>
    );
}

export default Tab3;