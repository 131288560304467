import verificationIlu from '../../../assets/illustration/verification_red-svg.svg'
import verification from '../../../assets/services_icon/verification.png'
import TabContainer from '../../../components/TabContainer';


const Tab3 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={verification} label={'Verification'}
                description={"If your channels meets all the requirements, we will be able to verify your channels. Either it is a general channel or an artist channel (OAC - Official Artist Channel). In addition, we will help you to get your YouTube play button rewards."}
                image={verificationIlu}
            />
        </div>
    );
}

export default Tab3;