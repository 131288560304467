import React, { useContext, useState } from 'react';

const HeaderColorContext = React.createContext()
const UpdateHeaderColorContext = React.createContext()

const ArrowColorContext = React.createContext()
const UpdateArrowColorContext = React.createContext()

const ServicesHeaderColorContext = React.createContext()
const UpdateServicesHeaderColorContext = React.createContext()

export function useHeaderColor() {
    return useContext(HeaderColorContext)
}
export function useUpdateHeaderColor() {
    return useContext(UpdateHeaderColorContext)
}

export function useArrowColor() {
    return useContext(ArrowColorContext)
}
export function useUpdateArrowColor() {
    return useContext(UpdateArrowColorContext)
}

export function useServicesHeaderColor() {
    return useContext(ServicesHeaderColorContext)
}
export function useUpdateServicesHeaderColor() {
    return useContext(UpdateServicesHeaderColorContext)
}

export function HeaderColorProvider({ children }) {
    const [headerColor, setHeaderColor] = useState(false)
    const [arrowColor, setArrowColor] = useState('home')
    const [servicesColor, setServicesColor] = useState(false)

    function updateHeaderColor(colorState) {
        setHeaderColor(colorState)
    }

    function updateArrowColor(colorState) {
        setArrowColor(colorState)
    }

    function updateServicesColor(colorState) {
        setServicesColor(colorState)
        console.log(servicesColor)
    }

    return (
        <HeaderColorContext.Provider value={headerColor}>
            <UpdateHeaderColorContext.Provider value={updateHeaderColor}>
                <ArrowColorContext.Provider value={arrowColor}>
                    <UpdateArrowColorContext.Provider value={updateArrowColor}>
                        <ServicesHeaderColorContext.Provider value={servicesColor}>
                            <UpdateServicesHeaderColorContext.Provider value={updateServicesColor}>
                                {children}
                            </UpdateServicesHeaderColorContext.Provider>
                        </ServicesHeaderColorContext.Provider>
                    </UpdateArrowColorContext.Provider>
                </ArrowColorContext.Provider>
            </UpdateHeaderColorContext.Provider>
        </HeaderColorContext.Provider>
    )
}


