import contentIdIlu from '../../../assets/illustration/content_id_red-svg.svg'
import contentId from '../../../assets/services_icon/content_id.png'
import TabContainer from '../../../components/TabContainer';

const Tab3 = () => {
    return (
        <div className='w-screen h-screen'>
            <TabContainer imageLabel={contentId} label={'Content ID'}
                description={"We will identify your music contents on YouTube through our Content ID system. We will claim and monetise them in order to earn revenue."}
                image={contentIdIlu}
            />
        </div>
    );
}

export default Tab3;